import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <p>Coming soon!</p>
    </div>
  );
}

export default App;
